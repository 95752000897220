<template>
  <!--
    页面名称：我的题库-首页
    开发人：黄wj
  -->
  <div class="questionBank">
    <div class="questionBank-bg">
      <div class="questionBank-bg-model">
        <div class="questionBank-bg-model-brief">
          <div class="questionBank-bg-model-brief-title1">
            <div class="title1-name">做题体系</div>
            <div class="title2-name">高阶人生提升学历</div>
          </div>
          <div class="questionBank-bg-model-brief-title2">
            要学会乐观的学习。子曰：“知之者不如好之者，好之者不如乐之者”，此
          </div>
        </div>
      </div>
    </div>
    <div class="questionBank-content" v-loading="loading">
      <div class="questionBank-content-model">
        <div
          class="questionBank-content-model-n"
          v-for="(item, index) in questionList"
          :key="index"
          @click="
            toPath(
              'questionBank/practice',
              item.id,
              item.crs_name,
              item.total_ques
            )
          "
        >
          <div class="questionBank-content-model-n-left">
            <div class="name">{{ item.crs_name }}</div>
            <div class="proportion">
              <div class="proportion-number">
                <div class="proportion-number-img">
                  <img src="@/assets/img/xx.png" alt />
                </div>
                <div class="proportion-number-data">
                  {{ item.total_ques }}题
                </div>
              </div>
              <div class="proportion-proportion">
                <div
                  class="proportion-proportion-data"
                  :style="{
                    color: classification(item.rate, 60)
                      ? '#FC6257'
                      : '#0D80FF',
                  }"
                >
                  {{ item.rate }}%
                </div>
                <div class="proportion-proportion-edata">
                  <div
                    class="proportion-proportion-edata-n"
                    :style="{
                      left: proportion(item.rate),
                      background: classification(item.rate, 60)
                        ? '#FC6257'
                        : '#0D80FF',
                    }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="questionBank-content-model-n-img">
            <!-- <div class="questionBank-content-model-n-img-img"></div> -->
          </div>
        </div>

        <div
          class="q404"
          v-if="questionList.length == null || questionList.length == 0"
        >
          <d404 :text="q404" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import d404 from "../data404";
import { getQuestion } from "../../../http/api/questionBank";
export default {
  created() {
    //生命周期-组件创建（数据和方法都已经被创建）
  },
  props: {
    //接收父组件的传值    props: ['fatherData'], ||  props: {fatherData:data,}
  },
  mounted() {
    this.getQuestion();
  },
  components: {
    d404,
  },
  data() {
    //数据

    return {
      q404: "抱歉 , 您暂时没有内容~~",
      questionList: [],
      loading: true,
    };
  },
  methods: {
    classification(data, sum) {
      var classification = true;
      if (data > sum) {
        classification = false;
      } else {
        classification = true;
      }
      return classification;
    },
    //方法
    //获取我的题库数据
    getQuestion() {
      getQuestion().then((res) => {
        if (res.code == 200) {
          // console.log(res);
          this.questionList = res.data;
          this.loading = false;
        }
      });
    },
    proportion(val) {
      //val   0-100   30
      var data = 100 - val;
      var proportion = "-" + data + "%";
      return proportion;
    },
    toPath(path, id, name, sum) {
      // console.log(name);
      // console.log(sum);
      this.$router
        .push(path + `?id=${id}&name=${name}&sum=${sum}`)
        .then(() => {})
        .catch((err) => err);
    },
  },
};
</script>

<style scoped>
.questionBank {
  width: 100%;
  min-height: 100px;
}
.questionBank-bg,
.questionBank-content {
  width: 100%;
}
.questionBank-bg-model,
.questionBank-content-model {
  width: 67%;
  margin: auto;
}
.questionBank-bg {
  background: url("../../../assets/img/questionBank.png");
  background-size: cover;
}
.questionBank-content {
  min-height: 100px;
}
.questionBank-bg-model {
  display: flex;
  padding-top: 5px;
  height: 155px;
  align-items: center;
  justify-content: center;
}
.questionBank-bg-model-img {
  width: 295px;
  height: 155px;
  background: red;
}
.questionBank-bg-model-brief {
  margin-left: -20px;
}
.questionBank-bg-model-brief-title1 {
  display: flex;
  align-items: flex-end;
}
.title1-name {
  font-size: 27px;
  font-weight: 700;
  color: #ffe164;
}
.title2-name {
  font-size: 20px;
  color: white;
  margin-left: 29px;
}
.questionBank-bg-model-brief-title2 {
  margin-top: 10px;
  color: white;
  font-size: 14px;
  opacity: 0.7;
}
.questionBank-content-model {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}
.questionBank-content-model-n {
  width: calc((100% - 20px * 3) / 3);
  height: 63px;
  background: white;
  border-radius: 6px;
  margin: 15px 0;
  margin: 0px 20px 20px 0px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  padding: 24px 0px;
}
.questionBank-content-model-n-img {
  width: 32px;
  height: 32px;
  /* margin-top: 24px; */
  margin-right: 33px;
  background: #eff0f5;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  background: url("../../../assets/img/questionss.png");
  background-size: 32px;
}
.questionBank-content-model-n:hover {
  transition: 0.25s;
}
.questionBank-content-model-n:hover .questionBank-content-model-n-img {
  background: url("../../../assets/img/question-active.png");
  background-size: 32px;
}
/* .questionBank-content-model-n:hover .questionBank-content-model-n-img-img {
  opacity: 1;
  transition: 0.25s;
  background: url("../../../assets/img/question-active.png");
  background-size: cover;
} */
img {
  width: 100%;
  height: 100%;
}
.name {
  width: 272px;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: #00264d;
  margin-left: 32px;
}
.proportion {
  display: flex;
  margin-top: 30px;
}
.proportion-number {
  display: flex;
}
.proportion-proportion {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.proportion-number-img {
  display: flex;
  width: 18px;
  height: 18px;
  margin-left: 32px;
}
.proportion-number-data {
  font-size: 14px;
  color: #657489;
  margin-left: 8px;
}
.proportion-proportion-data {
  color: #0080ff;
  font-size: 14px;
  font-weight: 600;
}
.proportion-proportion-edata {
  width: 80px;
  height: 8px;
  border-radius: 5px;
  background: #eff0f5;
  overflow: hidden;
  margin-left: 10px;
}
.proportion-proportion-edata-n {
  width: 80px;
  height: 8px;
  border-radius: 5px;
  background: #0080ff;
  position: relative;
  left: -100%;
  transition: 0.5s;
}
.questionBank-bg-model-img2 {
  width: 133px;
  height: 114px;
  background: red;
  margin-right: 100px;
  margin-top: 40px;
}
.login {
  width: 100px;
  height: 100px;
  background: red;
}
.questionBank-content-model-n-img-img {
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/e1.png");
  background-size: cover;
}
.q404 {
  margin: auto;
}
/* 最小 */
/*@media screen and (max-width: 960px) {*/
/*  .questionBank-content-model-n {*/
/*    width: 100%;*/
/*  }*/
/*}*/
/* 折中 */
/*@media screen and (min-width: 960px) and (max-width: 1281px) {*/
/*  .questionBank-content-model-n {*/
/*    width: 100%;*/
/*  }*/
/*}*/

/* 折中 */
@media screen and (min-width: 1025px) and (max-width: 1679px) {
  .questionBank-content-model-n {
    width: calc((100% - 20px * 2) / 2);;
  }
}
.questionBank-content-model:after {
  content: "";
  flex: auto;
}
/* 最小 */
@media screen and (max-width: 1024px) {
  /*.questionBank-bg {*/
  /*  width: 100%;*/
  /*  height: 100%;*/
  /*  background: url("../../../assets/img/questionBank.png") no-repeat;*/
  /*  background-size: contain;*/
  /*}*/
  .questionBank-bg-model,
  .questionBank-content-model {
    width: 100%;
    margin: auto;
  }
  .questionBank-bg-model-brief {
    padding: 0 10px;
    margin-left: 0;
  }
  .title1-name {
    font-size: 24px;
    font-weight: 700;
    color: #ffe164;
  }
  .title2-name {
    font-size: 16px;
    color: white;
    margin-left: 29px;
  }
  .questionBank-content-model-n {
    width: 100%;
    margin: 0 20px 20px 20px;
  }
  .questionBank-content-model-n-left{
    width: 250px;
  }
  .name {
    margin-left: 20px;
  }
  .proportion-number-img {
    display: flex;
    margin-left: 20px;
  }
  .questionBank-content-model-n-img {
    margin-right: 20px;
  }
}
</style>
