var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questionBank"},[_vm._m(0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"questionBank-content"},[_c('div',{staticClass:"questionBank-content-model"},[_vm._l((_vm.questionList),function(item,index){return _c('div',{key:index,staticClass:"questionBank-content-model-n",on:{"click":function($event){return _vm.toPath(
            'questionBank/practice',
            item.id,
            item.crs_name,
            item.total_ques
          )}}},[_c('div',{staticClass:"questionBank-content-model-n-left"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.crs_name))]),_c('div',{staticClass:"proportion"},[_c('div',{staticClass:"proportion-number"},[_vm._m(1,true),_c('div',{staticClass:"proportion-number-data"},[_vm._v(" "+_vm._s(item.total_ques)+"题 ")])]),_c('div',{staticClass:"proportion-proportion"},[_c('div',{staticClass:"proportion-proportion-data",style:({
                  color: _vm.classification(item.rate, 60)
                    ? '#FC6257'
                    : '#0D80FF',
                })},[_vm._v(" "+_vm._s(item.rate)+"% ")]),_c('div',{staticClass:"proportion-proportion-edata"},[_c('div',{staticClass:"proportion-proportion-edata-n",style:({
                    left: _vm.proportion(item.rate),
                    background: _vm.classification(item.rate, 60)
                      ? '#FC6257'
                      : '#0D80FF',
                  })})])])])]),_c('div',{staticClass:"questionBank-content-model-n-img"})])}),(_vm.questionList.length == null || _vm.questionList.length == 0)?_c('div',{staticClass:"q404"},[_c('d404',{attrs:{"text":_vm.q404}})],1):_vm._e()],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questionBank-bg"},[_c('div',{staticClass:"questionBank-bg-model"},[_c('div',{staticClass:"questionBank-bg-model-brief"},[_c('div',{staticClass:"questionBank-bg-model-brief-title1"},[_c('div',{staticClass:"title1-name"},[_vm._v("做题体系")]),_c('div',{staticClass:"title2-name"},[_vm._v("高阶人生提升学历")])]),_c('div',{staticClass:"questionBank-bg-model-brief-title2"},[_vm._v(" 要学会乐观的学习。子曰：“知之者不如好之者，好之者不如乐之者”，此 ")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"proportion-number-img"},[_c('img',{attrs:{"src":require("@/assets/img/xx.png"),"alt":""}})])}]

export { render, staticRenderFns }